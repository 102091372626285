<template>
  <li :data-damage="damage.id">
    <div class="p-12 mb-12 bg-gray-100 border rounded">
      <div class="flex justify-end w-full pb-3 mb-3 border-b">
        <BaseModel class="w-full" v-model="removeModel">
          <template #default="{ openModel }">
            <div class="flex justify-between w-full">
                <div class="w-full pr-5">
                    <BaseInput type="text" v-model="damage.name" :rules="rules('nane')" label="Damage name"
                        class="min-w-2xl"></BaseInput>
                </div>
              <BaseButton color="red" @click="openModel">
                Remove
              </BaseButton>
            </div>
          </template>

          <template #content>
            <h2>Are you sure you want to remove this damage: {{ currentDamageType.name }}?</h2>
            <div class="flex mt-10">
              <BaseButton color="gray" class="mr-5" @click="removeModel = false">
                Cancel
              </BaseButton>
              <BaseButton color="red" @click="removeDamage(damage.id)">
                Remove
              </BaseButton>
            </div>
          </template>
        </BaseModel>
      </div>
      <div v-if="serviceInterval(interval) === 'Blade'" class="w-full mb-100">
        <DamageAndRepair v-model="damage.windward" :damage="damage" :damage-radius="damage.radius" :blade-size="blade.blade_size" label="Windward" type="windward" image="/images/blade_up.svg"></DamageAndRepair>
      </div>
      <div v-if="serviceInterval(interval) === 'Blade'" class="w-full mb-100">
        <DamageAndRepair v-model="damage.leeward" :damage="damage" :damage-radius="damage.radius" :blade-size="blade.blade_size" label="Leeward" type="leeward" image="/images/blade_down.svg"></DamageAndRepair>
      </div>
      <div class="w-full mt-12">
        <BaseSelect v-model="damage.damage_type_id" :rules="rules('damage_type_id')" :options="damagetypeOptions" label="Damage Type"></BaseSelect>
      </div>
      <div v-if="serviceInterval(interval) === 'Blade'" class="w-full mt-8">
        <BaseInput v-model.number="damage.radius" :max="blade.blade_size" :showSymbols="false" :rules="rules('radius')" label="Radius (mm)" type="number"></BaseInput>
      </div>
      <div class="w-full mt-12">
        <div class="flex justify-between mb-12">
          <h2 class="text-2xl">
            Images
          </h2>
          <BaseButton color="green" @click="addImage">
            Add Image
          </BaseButton>
        </div>
        <ul class="flex flex-wrap w-full -mx-4">
          <BaseImageUpload v-for="(image, i) in damage.images" :key="i" :image="image" @removeImage="(image) => removeImage(image)"></BaseImageUpload>
        </ul>
      </div>
    </div>
    <div class="flex justify-center w-full mb-12">
      <BaseButton color="green" @click="addDamage">
        Add Damage
      </BaseButton>
    </div>
  </li>
</template>

<script>
// Helpers
import { sync, get } from "vuex-pathify";
import uuid from "@/helpers/generateUuid.js";
import rules from "@/helpers/rules.js";
import serviceInterval from "@/helpers/serviceInterval.js";

// Config
import damagetypeOptions from "@/config/options/damagetype.js";
// Database
import DefaultDamage from "@/database/defaults/damage.js";
import DefaultImage from "@/database/defaults/image.js";
import imageModel from "@/database/models/image.js";
import save from "@/helpers/save.js";

export default {
  props: {
    damage: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    "damage.radius"(radius) {
      if (radius > this.blade.blade_size) {
        this.damage.radius = this.blade.blade_size;
      }
    },
  },
  data() {
    return {
      removeModel: false,
    };
  },
  computed: {
    interval: sync("report@service_interval_id"),
    damages: sync("blade@damages"),
    vuexReport: sync("report"),
    blade: sync("blade"),
    damagetypeOptions: () => damagetypeOptions,
    currentDamageType() {
      return this.damagetypeOptions.filter((type) => {
        return (
          type.id === parseInt(this.damage.damage_type_id) ||
          type.id === this.damage.damage_type_id
        );
      })[0];
    },
  },
  methods: {
    serviceInterval,
    rules: rules("damage"),
    addDamage() {
      const before = this.damages.slice(0, this.index + 1);

      const after = this.damages.slice(this.index + 1, this.damages.length);

      this.damages = [
        ...before,
        {
          ...DefaultDamage,
          images: [],
          id: uuid(),
        },
        ...after,
      ];

      save(this.vuexReport, this.blade);
    },
    removeDamage(damageId) {
      this.damages = this.damages.filter((damage) => damage.id !== damageId);
      this.removeModel = false;
      save(this.vuexReport, this.blade);
    },
    getImages() {
      let images = [];

      this.damages.forEach((damage) => {
        if (damage.id === this.damage.id) {
          images = damage.images;
        }
      });

      return images;
    },
    addImage() {
      this.damage.images.push({ ...DefaultImage, id: uuid() });

      this.damages = this.damages.map((damage) => {
        if (damage.id === this.damage.id) {
          return this.damage;
        }

        return damage;
      });

      save(this.vuexReport, this.blade);
    },
    async removeImage(_image) {
      this.damage.images = this.damage.images.filter((image) => {
        return image.id !== _image.id;
      });

      await imageModel.remove(_image.filename);

      save(this.vuexReport, this.blade);
    },
  },
};
</script>
